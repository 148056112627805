import './App.css';
import { useEffect, useRef, useState, } from 'react';
import { ChakraProvider, Box, SimpleGrid, Text, Image, Button, Container, Flex, Divider, Link, IconButton, extendTheme, Heading } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useInViewport } from 'react-in-viewport';
import Carousel from "framer-motion-carousel";
import Lottie from 'react-lottie';
import { Link as Scroll } from "react-scroll";
import "aos/dist/aos.css";
import { useLottie, useLottieInteractivity } from "lottie-react";



import circle from "./images/circle.png";
import twitter from "./images/twitter.svg";
import discord from "./images/discord.svg";
import opensea from "./images/opensea.svg";
import logoanimation from "./lotties/logo";
import heroanimation from "./lotties/888heroanimation.json";
import growing from "./lotties/InnerCircle-Growing3.json";
import svs from "./images/svs.jpg";
import gradientbg from "./images/purplegradientbg.svg"
import coolcats from "./images/coolcats.png"
import steveaoki from "./images/steveaoki.png"
import jasonseife from "./images/jasonseife.png"
import maalavidaa from "./images/maalavidaa.png"
import metalheads from "./images/metalheads.gif"
import Aos from 'aos';
import herovideo from "./videos/888tweetvideo.mp4";
import bgsmall from "./images/purplebgsmall.svg";
import dgimage from "./images/23C1B992-E578-4E38-840C-F1DD5EFD15F8.jpeg";


const options = {
  animationData: growing,
};
const Growing = () => {
  const lottieObj = useLottie(options);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "scroll",
    actions: [

      {
        visibility: [0, 1],
        type: "seek",
        frames: [0, 360],
      },
    ],
  });
  return Animation;
};









function App() {
  const [display, changeDisplay] = useState("none")

  const logoLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: logoanimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }

  };
  const heroops = {
    loop: true,
    autoplay: true,
    animationData: heroanimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }

  };
  const growinops = {
    loop: true,
    autoplay: true,
    animationData: growing,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }

  };


  const theme = extendTheme({
    fonts: {
      heading: "neue-haas-grotesk-display",
      body: "ivypresto-display",
    },
  });

  const colors = ["#f90", "#ef0", "#e0f"];
  const images = [

    {
      text: "Steve Aoki",
      image: steveaoki
    }
  ];
  const imagespast = [
    {
      text: "Jason Seife",
      image: jasonseife
    },
    {
      text: "Maalavidaa",
      image: maalavidaa
    },
    {
      text: "SVS X 888",
      image: svs
    }
  ];

  const ref = useRef(null);
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: "-400px" },
    { disconnectOnLeave: false },
    {}
  );

  useEffect(() => {
    Aos.init({
      duration: 1000
    });
  }, []);





  return (
    <ChakraProvider theme={theme} >
      <Box minHeight="100vh" bg="black" >

        {/* Header */}

        <Box bg="black">
          <Container bg="black" display="flex" maxW="container.xl">
            <Box w="100%" bg="black" display="flex" pt="10" justifyContent={["center", "center", "space-between", "space-between"]}>
              <Box display="flex" alignItems="center" justifyContent="left" pl="0">
                <Box display="flex" mt="-60px" >

                  <Lottie
                    options={logoLottieOptions}

                    width={300}
                  />
                </Box>
                <Box>

                </Box>
              </Box>
              {/*}
              <Box display={["none", "none", "flex", "flex"]}  >

                <Scroll to="drops" smooth={true} duration={1000}>
                  <Button fontFamily="proxima-nova" textColor="white" variant="outline" mr="5" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                    DROPS

                  </Button>
                </Scroll>
                <Scroll to="earlyaccess" smooth={true} duration={1000}>
                  <Button fontFamily="proxima-nova" textColor="white" variant="outline" mr="5" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                    EARLY ACCESS

                  </Button>
                </Scroll>
                <Link href="https://opensea.io/collection/888innercircle" isExternal>
                    <Button fontFamily="proxima-nova" textColor="white" variant="outline" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                      OPENSEA

                    </Button>

                  </Link>
                  <Link href="https://form.typeform.com/to/NIyaWEzu" isExternal>
                        <Button fontFamily="proxima-nova" textColor="white" variant="outline" ml="3" mr="3" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                          ARTIST COLLAB

                        </Button>
                      </Link>


              </Box>
  */}
              <Box display="flex" alignItems="top">

                <Box display={["none", "none", "flex", "flex"]} alignItems="top">
                  <Link mt="3" href="https://twitter.com/crypto888crypto">
                    <Image mr="7" htmlWidth="30px" boxsize="90px" src={twitter} />
                  </Link>

                  <Link mt="3" href="https://discord.com/invite/888innercircle" pr="5">
                    <Image htmlWidth="30px" boxsize="90px" src={discord} />
                  </Link>
                </Box>
                <IconButton

                  aria-label="Open Menu"
                  size="lg"
                  bg="black"
                  color="white"
                  icon={<HamburgerIcon />}
                  display={["flex", "flex", "flex", "flex"]}
                  onClick={() => changeDisplay('flex')}
                  _hover={{ bg: "white", textColor: "black" }}
                >

                </IconButton>
              </Box>


            </Box>


            <Flex w="100vw"
              zIndex={20}
              h="100vh"
              pos="fixed"
              top="0"
              left="0"
              flexDir="column"
              bg="black"
              overflowY="auto"
              display={display}
            >
              <Container maxW="container.xl">
                <Flex justify="flex-end" pt="10" pr="2">
                  <IconButton

                    aria-label="Close Menu"
                    size="lg"
                    icon={<CloseIcon />}
                    onClick={() => changeDisplay('none')}

                  />
                </Flex>
              </Container>


              <Flex display="flex" flexDir="column" justifyContent="center" align="center" pt="10">

                <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" ml={6} mr={6}>
                  <Scroll to="drops" smooth={true} duration={1000}>
                    <Button m="5" onClick={() => changeDisplay('none')} fontFamily="proxima-nova" textColor="white" variant="outline" mr="5" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                      DROPS

                    </Button>
                  </Scroll>
                  <Scroll to="earlyaccess" smooth={true} duration={1000}>
                    <Button m="5" onClick={() => changeDisplay('none')} fontFamily="proxima-nova" textColor="white" variant="outline" mr="5" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                      EARLY ACCESS

                    </Button>
                  </Scroll>
                  <Link href="https://opensea.io/collection/888innercircle" isExternal>
                    <Button m="5" fontFamily="proxima-nova" textColor="white" variant="outline" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                      OPENSEA GENESIS

                    </Button>

                  </Link>
                  <Link href="https://opensea.io/collection/888innercirclethedrops" isExternal>
                    <Button m="5" fontFamily="proxima-nova" textColor="white" variant="outline" ml="3" mr="3" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                      OPENSEA THE DROPS

                    </Button>
                  </Link>
                  <Link href="https://form.typeform.com/to/NIyaWEzu" isExternal>
                    <Button m="5" fontFamily="proxima-nova" textColor="white" variant="outline" ml="3" mr="3" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                      ARTIST COLLAB

                    </Button>
                  </Link>

                  <Link href="https://form.typeform.com/to/V4o79mZI" isExternal>
                    <Button m="5" fontFamily="proxima-nova" textColor="white" variant="outline" ml="3" mr="3" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                      EARLY ACCESS COLLAB

                    </Button>
                  </Link>
                  <Box m="5" display="flex">
                    <Link border="2px" borderColor="#AD6AEC" borderRadius="15" href="https://discord.com/invite/888innercircle" mr="5" p="3">
                      <Image htmlWidth="30px" boxsize="90px" src={discord} />
                    </Link>
                    <Link border="2px" borderColor="#AD6AEC" borderRadius="15" href="https://twitter.com/crypto888crypto" p="3">
                      <Image htmlWidth="30px" boxsize="90px" src={twitter} />
                    </Link>
                  </Box>
                </Box>


              </Flex>
            </Flex>


          </Container>
        </Box>

        {/* Hero */}

        <Box mt="50" display="flex" width="100%" bg="black" >
          <Container display="flex" maxW="container.xl"  >
            <SimpleGrid minChildWidth="300px" spacingX="40px" >
              <Box data-aos-delay="200" data-aos="fade-right" display="flex"  >

                <Box display="flex"  >
                  {/*
                  <Lottie
                    options={heroops}

                    width={600}
                  />
              */}
                  <video
                    disableRemotePlayback
                    autoPlay
                    loop
                    muted


                    alt="" >
                    <source src={herovideo} type="video/mp4" />

                  </video>
                </Box>




              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">


                <Box data-aos="fade-up" data-aos-delay="400" display="flex" flexDirection="column" justifyContent="center" border="3px" mt="20" >


                  <Heading textColor="white" fontWeight="semi-bold">
                    Welcome to the Inner Circle
                  </Heading>
                  <Text mb={6} mt={6} fontSize="2xl" textColor="white" text >
                    <p><b>888 Inner Circle</b> is a global tokenized membership club. Its members hold a collection of NFTs— digital collectibles living on the Ethereum blockchain.</p><p>The Inner Circle NFT doubles as an #888InnerCircle membership card which grants access to members-only benefits throughout the 888 ecosystem.</p>
                  </Text>


                 </Box>





               </Box>


             </SimpleGrid>


           </Container>


           {/* Growing */}
         </Box>

         {/* Growing */}

         {/*
       <Box boxSize={["100%", "100%", "70%", "60%"]} ml="auto" mr="auto" h="50%" display="flex" justifyContent="center"  >
       <video 
               autoPlay 
               loop
               muted
               alt="" >
               <source src={growvideo} type="video/mp4"/>
               
               </video>
         </Box>




         <Box display="flex" mt="10" >

           <Lottie
             options={growinops}

             width={900}
           />
         </Box>
 */}


         {/* Upcoming Drops */}

         <Box id="drops" textColor="white" textAlign="center" backgroundImage={gradientbg} pt="20" pb="10" ref={ref}>
           <Container maxW="container.xl">
             <Box data-aos-offset="400" data-aos="fade-down"  >
               <Heading fontWeight="semi-bold" >
                 Upcoming Drops
               </Heading>
               <Text fontSize="2xl" mt="3">
                 Cool Cats X 888
               </Text>
               <Text fontSize="lg" mt="3">
                 Genesis #4
               </Text>
             </Box>
             <Box data-aos-offset="350" data-aos="fade-up" >
               <Image ml="auto" mr="auto" src={coolcats} mt="3" />

               <Box display="flex" justifyContent="center">

                 <Link href="https://opensea.io/collection/888innercirclethedrops">
                   <Button  mt="5" fontFamily="proxima-nova" textColor="white" variant="outline" borderRadius="15" borderColor="#AD6AEC" _hover={{ bg: '#AD6AEC', textColor: "white" }}>

                     OPENSEA

                   </Button>
                 </Link>

               </Box>
             </Box>
           </Container>

         </Box>


         {/* Future Drops*/}

         {/*
         <Box pt="20" textColor="white">
           <Heading pb="10" textAlign="center">
             Future Drops
           </Heading>
           <SimpleGrid minChildWidth="300px" spacingX="40px" spacing="40px" mt="10" >
             <Box ml="auto" mr="auto">
               <Text textAlign="center" fontSize="2xl" >
                 Cool Cats x 888
               </Text>
               <Image width="70%" mt="5" display="block" ml="auto" mr="auto" src={coolcats} />

             </Box>
             <Box ml="auto" mr="auto">
               <Text textAlign="center" fontSize="2xl" >
                 Steve Aoki
               </Text>
               <Image width="70%" mt="5" display="block" ml="auto" mr="auto" src={steveaoki} />

             </Box>
           </SimpleGrid>


         </Box>
         */}

         {/* Past Drops*/}
         {/*

         <Box pt="20" textColor="white">
           <Heading pb="10" textAlign="center">
             Past Drops
           </Heading>
           <SimpleGrid minChildWidth="300px" spacingX="40px" spacing="40px" mt="10" >
             <Box ml="auto" mr="auto">
               <Text textAlign="center" fontSize="2xl" >
                 Jason Seife
               </Text>
               <Image width="70%" mt="5" display="block" ml="auto" mr="auto" src={jasonseife} />

             </Box>
             <Box ml="auto" mr="auto" >
               <Text textAlign="center" fontSize="2xl" >
                 Maalavidaa
               </Text>
               <Image width="70%" mt="5" display="block" ml="auto" mr="auto" src={maalavidaa} />

             </Box>
           </SimpleGrid>


         </Box>
       */}


         {/* Carousel Version*/}
         <Box backgroundImage={bgsmall} backgroundSize="auto" backgroundPosition="center" >
           <Container data-aos="fade-up" data-aos-offset="400" maxW="container.xl" border="0" >
             <SimpleGrid minChildWidth="300px" >
               <Box pt="10" >


                 <Heading fontWeight="semi-bold" display="block" pb="10" pt="3" ml="auto" mr="auto" textAlign="center" textColor="white" >
                   Future Drops
                 </Heading>

                 <Text display="block" textColor="white" textAlign="center" fontSize="2xl" pb="3" >
                       Steve Aoki
                     </Text>
                 <Box w={["100%", "100%", "50%", "70%"]} border="0" ml="auto" mr="auto" display="flex" justifyContent="center" position="relative">

                   <Box display="flex">


                     <Image w="100%"  src={steveaoki} />
                   </Box>
                   {/*Carousel Steve Aoki

                   <Carousel autoPlay={false} >
                     {images.map((item, i) => (
                       <>
                         <Text textColor="white" textAlign="center" fontSize="2xl" pb="3" >
                           {item.text}
                         </Text>
                         <img
                           draggable="false"
                           src={item.image}
                           key={i}
                           width="100%"
                           alt=""
                         />
                       </>
                     ))}

                   </Carousel>
                     */}

                 </Box>
               </Box>
               <Box pt="10" >


                 <Heading pt="3" fontWeight="semi-bold" display="block" pb="10" ml="auto" mr="auto" textAlign="center" textColor="white"   >
                   Past Drops
                 </Heading>


                 <Box w={["100%", "100%", "50%", "70%"]} ml="auto" mr="auto" display="flex" justifyContent="center" position="relative">

                   <Carousel autoPlay={false}>
                     {imagespast.map((item, i) => (
                       <>
                         <Text textColor="white" textAlign="center" fontSize="2xl" pb="3" >
                           {item.text}
                         </Text>
                         <img
                           draggable="false"
                           src={item.image}
                           key={i}
                           width="100%"
                           alt=""
                         />
                       </>
                     ))}

                   </Carousel>

                 </Box>
               </Box>
             </SimpleGrid>

           </Container>
         </Box>


         {/* Early Access*/}


         <Box id="earlyaccess" textColor="white" textAlign="center" backgroundImage={gradientbg} pt="20" pb="10">
           <Container maxW="container.xl">
             <Box data-aos="fade-down" data-aos-offset="400">

               <Heading fontFamily="neue-haas-grotesk-display" fontWeight="semi-bold">
                 Early Access
               </Heading>
               <Text fontSize="2xl" mt="3">
                 DGFamily by Dolce&Gabbana and UNXD
               </Text>
               <Box display="flex" justifyContent="center" mt="3">
                 <Link href="https://discord.gg/unxd">
                   <Image mr="2" ml="2" htmlWidth="30px" boxsize="90px" src={discord} />
                 </Link>
                 <Link href="https://twitter.com/UNXD_NFT">
                   <Image mr="2" ml="2" htmlWidth="30px" boxsize="90px" src={twitter} />
                 </Link>
               </Box>

             </Box>
             <Box data-aos="fade-up" data-aos-offset="400" >
               <Image width="600px" ml="auto" mr="auto" src={dgimage} mt="5" />
             </Box>
           </Container>


         </Box>


         {/* Early Access Past Drops*/}

         {/*            

         <Box pt="20" textColor="white">
           <Heading pb="10" textAlign="center">
             Early Access Past Drops
           </Heading>
           <SimpleGrid minChildWidth="300px" spacingX="40px" spacing="40px" mt="10" >
             <Box ml="auto" mr="auto">
               <Text textAlign="center" fontSize="2xl" >
                 Bones & Bananas
               </Text>
               <Image width="70%" mt="5" display="block" ml="auto" mr="auto" src={bonesbananas} />

             </Box>
             <Box ml="auto" mr="auto">
               <Text textAlign="center" fontSize="2xl" >
                 Grillz Gang
               </Text>
               <Image width="70%" mt="5" display="block" ml="auto" mr="auto" src={grillzgang} />

             </Box>
           </SimpleGrid>


         </Box>
        */}

         {/* Footer */}

         <Container maxW="container.xl">
           <Box pb="10" display="flex" justifyContent="center" alignItems="center"   >

             <Divider borderBottomWidth="1px" opacity="1" borderColor="white" />
             <Image src={circle} ml="3" mr="3" width="40px" className="App-logo" />
             <Divider borderBottomWidth="1px" opacity="1" borderColor="white" />
           </Box>
           <Box display={["none", "none", "none", "flex"]} justifyContent="space-between" pb="5" alignItems="center">
             <Text fontFamily="proxima-nova" textColor="white">
               2022©888
             </Text>
             <Scroll to="drops" smooth={true} duration={1000}>
               <Button fontFamily="proxima-nova" variant='link' textColor="white">
                 DROPS
               </Button>
             </Scroll>
             <Scroll to="earlyaccess" smooth={true} duration={1000}>
               <Button fontFamily="proxima-nova" variant='link' textColor="white">
                 EARLY ACCESS
               </Button>
             </Scroll>
             <Text fontFamily="proxima-nova" textColor="white">
               All 888InnerCircle drops will always be free to members (Excluding network Gas charges)
             </Text>
             <Link href="https://discord.gg/888tnw">
               <Image htmlWidth="30px" boxsize="90px" src={discord} />
             </Link>
             <Link href="https://twitter.com/crypto888crypto">
               <Image htmlWidth="30px" boxsize="90px" src={twitter} />
             </Link>

           </Box>
           <Box display={["flex", "flex", "flex", "none"]} justifyContent="center" flexDirection="column" alignItems="center" pb="10">
             <Text fontFamily="proxima-nova" textColor="white">
               2022©888
             </Text>
             <Button fontFamily="proxima-nova" variant='link' textColor="white" mt="5">
               DROPS
             </Button>
             <Button fontFamily="proxima-nova" variant='link' textColor="white" mt="5">
               EARLY ACCESS
             </Button>
             <Text fontFamily="proxima-nova" textAlign="center" textColor="white" mt="5">
               All 888InnerCircle drops will always be free to members (Excluding network Gas charges)
             </Text>
             <Box display="flex" flexDirection="row" mt="5">
               <Link href="https://discord.com/invite/888innercircle" pr="5">
                 <Image htmlWidth="30px" boxsize="90px" src={discord} />
               </Link>
               <Link href="https://twitter.com/crypto888crypto">
                 <Image htmlWidth="30px" boxsize="90px" src={twitter} />
               </Link>
             </Box>

           </Box>
         </Container>



       </Box>
     </ChakraProvider >
   );
 }

 export default App;
